import React from 'react';

import gsap from 'gsap';

import logo from '../img/Fox_Logo.gif';
import './Artwork.scss';

interface ArtworkDetails {
    author: string;
    title?: string;
}

const LogoGif = () => <>
    <img className="logo-gif" src={logo} alt="Fox Stevenson Boi Logo"></img>
</>;

const ArtworkInfo = (props: ArtworkDetails) => {
    const {author, title} = props;

    if (title) {
        return <>
            <div className="artwork-info">
                <p className="artwork-title">{title}</p>
                <p className="artwork-author">By {author}</p>
            </div>
        </>;
    }

    return <>
        <div className="artwork-info">
            <p className="artwork-author big">By {author}</p>
        </div>
    </>;
};

const LowerThird = (props: ArtworkDetails) => <>
    <div className="lower-third">
        <ArtworkInfo {...props} />
        <LogoGif />
    </div>
</>;

function randomIntFromInterval(min: number, max: number) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
}

function calculateAnimationDuration(start: number, end: number) {
    const pixelsPerSecond = 300;
    return (start - end) / pixelsPerSecond;
}

export type ArtworkProps = {
    imageSrc: string;
    author: string;
    title?: string;
};

type _ArtworkProps = ArtworkProps & {
    callback: gsap.Callback
};

export class Artwork extends React.Component<_ArtworkProps> {
    private containerRef: React.RefObject<HTMLDivElement>;
    private imgRef: React.RefObject<HTMLImageElement>;
    private tween?: gsap.core.Tween;
    private rotation: number;

    constructor(props: _ArtworkProps) {
        super(props);

        this.containerRef = React.createRef();
        this.imgRef = React.createRef();
        this.rotation = randomIntFromInterval(-13, 13);
    }

    render() {
        return (
            <div 
                style={{
                    opacity: 0
                }}
                className="artwork-frame"
                ref={this.containerRef}>
                <img
                    ref={this.imgRef}
                    onLoad={this.onImageLoad.bind(this)}
                    className="artwork" 
                    alt={this.props.title || 'Fox Stevenson fan artwork'}
                ></img>
                <LowerThird {...this.props} />
            </div>
        );
    }

    componentDidMount() {
        this.imgRef.current!.src = this.props.imageSrc;
    }

    onImageLoad() {
        if (this.tween)
            return;

        const containerWidth = this.containerRef.current!.clientWidth;
        const containerHeight = this.containerRef.current!.clientHeight;

        const { innerWidth: windowWidth, innerHeight: windowHeight } = window;

        const verticalOffset = 20 + (windowHeight - containerHeight) / 2;
        
        const tweenStart = windowWidth + containerWidth;
        const tweenEnd = -2 * containerWidth;
        const tweenDuration = calculateAnimationDuration(tweenStart, tweenEnd);

        this.tween = gsap.fromTo(this.containerRef.current, 
            {
                opacity: 1,
                x: tweenStart,
                y: verticalOffset,
                rotate: this.rotation,
                ease: 'linear'
            }, {
                x: tweenEnd,
                y: verticalOffset,
                rotate: this.rotation,
                ease: 'linear',
                duration: tweenDuration,
                onComplete: this.onTimerFinished.bind(this)
            });
    }

    private onTimerFinished() {
        this.props.callback(this.props.imageSrc);
    }
}